exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adesaoderedes-tsx": () => import("./../../../src/pages/adesaoderedes.tsx" /* webpackChunkName: "component---src-pages-adesaoderedes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-archive-blog-tsx": () => import("./../../../src/templates/archive/blog.tsx" /* webpackChunkName: "component---src-templates-archive-blog-tsx" */),
  "component---src-templates-archive-category-tsx": () => import("./../../../src/templates/archive/category.tsx" /* webpackChunkName: "component---src-templates-archive-category-tsx" */),
  "component---src-templates-single-flexible-page-tsx": () => import("./../../../src/templates/single/flexible-page.tsx" /* webpackChunkName: "component---src-templates-single-flexible-page-tsx" */),
  "component---src-templates-single-flexible-program-tsx": () => import("./../../../src/templates/single/flexible-program.tsx" /* webpackChunkName: "component---src-templates-single-flexible-program-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/single/page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */),
  "component---src-templates-single-post-tsx": () => import("./../../../src/templates/single/post.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */)
}

