import { useEffect, useState, Dispatch, SetStateAction } from 'react'

type Response<T> = [T, Dispatch<SetStateAction<T>>]

export function usePersistedState<T>(
  key: string,
  initialState: T
): Response<T> {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') return initialState
    const storageValue = localStorage.getItem(key)
    return storageValue ? JSON.parse(storageValue) : initialState
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(state))
    }
  }, [state, setState])

  return [state, setState]
}
