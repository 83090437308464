export const cookieConfig = {
  current_lang: 'pt',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  gui_options: {
    consent_modal: {
      layout: 'cloud', // box/cloud/bar
      position: 'bottom center', // bottom/middle/top + left/right/center
      transition: 'slide', // zoom/slide
      swap_buttons: false // enable to invert buttons
    },
    settings_modal: {
      layout: 'box', // box/bar
      // position: 'left',           // left/right
      transition: 'slide' // zoom/slide
    }
  },

  mode: 'opt-in',
  cookie_expiration: 365,
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 365,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    const dataLayer = window.dataLayer || []
    setTimeout(() => {
      dataLayer.push({
        event: 'consentChoice'
      })
    }, 500)
  },

  onAccept: function (cookie) {},

  onChange: function (cookie, changed_preferences) {
    const dataLayer = window.dataLayer || []
    setTimeout(() => {
      dataLayer.push({
        event: 'consentUpdated'
      })
    }, 800)
  },

  languages: {
    pt: {
      consent_modal: {
        title: 'Privacidade',
        description:
          'O nosso site utiliza cookies para garantir que você tenha uma experiência personalizada. O Centro Lemann também poderá utilizá-los para permitir o início de sessões seguras, gravar os detalhes de seu início de sessão, funcionalidades das páginas, coletar estatísticas e oferecer conteúdos adequados aos seus interesses.  <a href="/politica-de-privacidade" class="cc-link">política de privacidade</a>',
        primary_btn: {
          text: 'Aceitar',
          role: 'accept_all' // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Preferências',
          role: 'settings' // 'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Preferências de privacidade',
        save_settings_btn: 'Salvar',
        accept_all_btn: 'Aceitar todos',
        reject_all_btn: 'Rejeitar todos',
        close_btn_label: 'Fechar',
        cookie_table_headers: [
          { col1: 'Nome' },
          { col2: 'Domínio' },
          { col3: 'Expira em' },
          { col4: 'Descrição' }
        ],
        blocks: [
          {
            description:
              'Este site usa cookies para garantir as funcionalidades básicas do site e melhorar sua experiência online. Você pode escolher cada categoria para ativar/desativar sempre que quiser. Para obter mais detalhes sobre cookies e outros dados confidenciais, leia na íntegra a nossa <a href="/politica-de-privacidade" class="cc-link">Política de privacidade</a>.'
          },
          {
            title: 'Cookies estritamente necessários',
            description:
              'Utilizamos em nosso site somente os cookies estritamente necessários que são essenciais para o funcionamento adequado do site, com eles, não armazenamos dados confidenciais ou pessoais.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
            }
          },
          {
            title: 'Cookies de analytics e performance',
            description:
              'Cookies de analytics são dados anonimizados que permitem a coleta de estatísticas de uso e navegação do site centrolemann.org.br. Cookies de performance permitem que o site se comporte de acordo com o visitante, ajustando à sua localização, idioma de preferência, etc.',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: true,
              readonly: false
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 anos',
                col4: 'Google Analytics',
                is_regex: true
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 dia',
                col4: 'Google Analytics'
              }
            ]
          },
          {
            title: 'Ajuda',
            description:
              'Para mais informações, por favor consulte nossa <a class="cc-link" href="/politica-de-privacidade">política de privacidade</a>.'
          }
        ]
      }
    }
  }
}
