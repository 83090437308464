import { createContext, useEffect } from 'react'
import { usePersistedState } from '@utils/hooks'

interface IThemeContext {
  highContrast: boolean
  toggleHighContrast: () => void
  fontSize: number
  changeFontSize: (value: number) => void
}

export const ThemeContext = createContext<IThemeContext | null>(null)

export const ThemeProvider = ({ children }) => {
  const [highContrast, setHighContrast] = usePersistedState<boolean>(
    'highContrast',
    false
  )
  const [fontSize, setFontSize] = usePersistedState<number>('fontSize', 0)

  const toggleHighContrast = () => {
    setHighContrast(!highContrast)
    document.body.classList.toggle('dark')
  }

  const changeFontSize = (value: number) => {
    if (value > 4 || value < 0) return

    setFontSize(value)
    const fontValue = `${value * 0.1 + 1}em`
    document.querySelector('html').style.fontSize = fontValue
  }

  // apply initial values
  useEffect(() => {
    highContrast
      ? document.body.classList.add('dark')
      : document.body.classList.remove('dark')
    const fontValue = `${fontSize * 0.1 + 1}rem`
    document.querySelector('html').style.fontSize = fontValue
  }, [])

  return (
    <ThemeContext.Provider
      value={{ highContrast, toggleHighContrast, fontSize, changeFontSize }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
