import '@styles/fonts.css'
import '@styles/globals.css'

import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/lazy'
import 'swiper/css/pagination'
import 'swiper/css/grid'

import './src/analytics'
import 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import { cookieConfig } from './src/cookie-consent'

import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { AnimatePresence } from 'framer-motion'
import { MinScreenProvider } from '@context/minScreen'
import { ThemeProvider } from '@context/ThemeContext'
import { theme, GlobalStyles } from 'twin.macro'
import { isServer } from '@utils/functions'

export const wrapRootElement = ({ element }) => {
  return <Wrapper element={element}></Wrapper>
}

const Wrapper = ({ element }) => {
  const useMountEffect = func => React.useEffect(func, [])

  useMountEffect(() => {
    console.log(`

               _____ ______ _____  _____
              / ____|  ____|  __ \\|  __ \\
   ___  _ __ | (___ | |__  | |__) | |__) |
  / _ \\| '_ \\ \\___ \\|  __| |  _  /|  ___/
 | (_) | | | |____) | |____| | \\ \\| |
  \\___/|_| |_|_____/|______|_|  \\_\\_|


Este site é mantido e desenvolvido por onSERP Marketing. Quer saber mais sobre a gente? Entra no nosso site: onserp.com.br
    `)

    let cc
    try {
      cc = window.initCookieConsent()
    } catch (error) {
      console.error(error)
    }
    if (cc) {
      cc.run(cookieConfig)
    }
  })
  return (
    <ThemeProvider>
      <GlobalStyles />
      <MinScreenProvider screens={theme`screens`}>
        <AnimatePresence exitBeforeEnter>
          <SimpleReactLightbox>{element}</SimpleReactLightbox>
        </AnimatePresence>
      </MinScreenProvider>
    </ThemeProvider>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition
}) => {
  if (
    prevRouterProps &&
    location.pathname !== prevRouterProps.location.pathname &&
    location.hash !== ''
  ) {
    return false
  }

  return [0, 0]
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const hash = location.hash

  //check if page path has changed
  if (prevLocation === null || location.pathname !== prevLocation.pathname) {
    // scroll to id if there is a hash in the url
    if (hash) {
      const hashWithoutHash = hash.substring(1)

      window.addEventListener(
        `loaded-component-${hashWithoutHash}`,
        function scrollToHash() {
          window.removeEventListener(
            `loaded-component-${hashWithoutHash}`,
            scrollToHash
          )

          const element = document.querySelector(hash)

          if (element && element.id === hashWithoutHash) {
            const yOffset = -120
            const y =
              element.getBoundingClientRect().top + window.scrollY + yOffset

            setTimeout(() => {
              window.scrollTo({ top: y, behavior: 'smooth' })

              console.log(`element scrolled ${hash}`, element)
            }, 1000)
          }
        }
      )
    }
  }
}
